<template>
    <section class="container mx-auto">
        <Headline>Book a Discovery Call Now</Headline>
        <p>Ready to explore how AI can transform your business? Let's talk.</p>

        <div>
            <h3>Why Book a Discovery Call?</h3>
            <p>
                Personalized Insight: Get tailored advice on how AI can address
                your specific challenges.
            </p>
            <p>
                Direct Access: Speak directly with me to understand how we can
                work together.
            </p>
            <p>
                No Obligation: Use this opportunity to ask questions and
                evaluate if we're the right fit, with no obligation to proceed
                further.
            </p>
        </div>

        <div>
            <h3>How It Works:</h3>
            <ul>
                <li>
                    Choose Your Time: Simply select a day and time that works
                    best for you from the calendar below.
                </li>
                <li>
                    Confirm Your Slot: Fill in your details so I can get in
                    touch and prepare for our call.
                </li>
                <li>
                    Let's Connect: You'll receive a confirmation email with a
                    link to our video call. Just click the link at the scheduled
                    time, and you're all set!
                </li>
            </ul>
        </div>

        <div>
            <h3>What to Expect:</h3>
            <ol>
                <li>
                    Discovery Session: We'll discuss your business, challenges,
                    and goals to identify how AI can help.
                </li>
                <li>
                    Customized Plan: I'll provide a high-level overview of how
                    we can work together to achieve your objectives.
                </li>
                <li>
                    Next Steps: If you're interested in moving forward, we'll
                    discuss the next steps and how we can collaborate.
                </li>
            </ol>
        </div>

        <code>[Embedded Interactive Calendar]</code>
    </section>
</template>
