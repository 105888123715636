<script setup>
    //

    useSeoMeta({
        title: 'LV Consulting',
        description:
            'LV Consulting is your way to leverage artificial intelligence and machine learning to optimize your business processes and drive innovation.',
    });
</script>

<template>
    <Hero />
    <SympathisingCards />
    <TransitionToSolution />
    <TheSolutionSteps />
    <HowMeIsDifferent />
    <Testimonial />
    <ServiceCards />
    <BookingCalendar />
</template>
