<template>
    <section class="sympathising-cards">
        <Headline>
            As a leader in your industry, you're facing challenges that come
            with legacy processes and disjointed systems.
        </Headline>
        <div class="cards">
            <Card>
                <template #title>Manual Processes</template>
                Outdated manual tasks are slowing down your team, limiting
                efficiency and stifling potential for innovation.
            </Card>
            <Card>
                <template #title>Data Fragmentation</template>
                Without a centralized system, insights into your best-performing
                areas are missed, hindering strategic decision-making.
            </Card>
            <Card>
                <template #title>Inefficient Systems</template>
                Your information management system struggles with speed and
                user-friendliness, delaying updates and frustrating users.
            </Card>
            <Card>
                <template #title>Stalled Innovation</template>
                Valuable talent is tied up in routine tasks, diverting focus
                from projects that could enhance your market position and SEO.
            </Card>
        </div>
    </section>
</template>
