<template>
    <section class="solution-steps">
        <Headline>The Solution: The Simple 4-Step System</Headline>
        <ol>
            <li><h3>In-Depth Discovery</h3></li>
            <p>
                Our journey begins with a thorough understanding of your
                operations. Through calls, interviews, or a dedicated in-person
                day, we delve into your processes to pinpoint areas ripe for AI
                enhancement. This step ensures our recommendations are precisely
                tailored to your unique needs.
            </p>
            <li><h3>Custom AI Blueprint</h3></li>
            <p>
                You'll receive a strategic deliverable that outlines key
                improvement areas and matches them with specific AI
                interventions. This AI Blueprint acts as your high-level guide,
                offering the clarity of an instruction manual with the
                flexibility to adapt to your company's specific requirements.
            </p>
            <li><h3>Guided Implementation Support</h3></li>
            <p>
                With your AI strategy in hand, embark on the implementation
                phase with our expert guidance. Our premium support package
                includes weekly one-hour video calls and an optional monthly
                in-person visit over three months, providing the insights and
                advice you need to navigate AI integration successfully.
            </p>
            <li><h3>Reflection and Future Strategy</h3></li>
            <p>
                After witnessing the transformative results of your AI
                initiatives, we'll help you look to the future. Celebrating your
                successes, we'll also outline potential next steps, ensuring
                your company continues on its path of innovation and growth,
                fully equipped with the knowledge and experience gained.
            </p>
        </ol>
    </section>
</template>
