<template>
    <div class="card">
        <h3>
            <slot name="title"></slot>
        </h3>
        <p>
            <slot></slot>
        </p>
    </div>
</template>
