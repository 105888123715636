<template>
    <section class="ai-services">
        <Headline
            >Streamlined AI Solutions for Business Transformation</Headline
        >

        <Card>
            <template #title> Customer Support Revolutionized </template>
            Implement AI chatbots to provide round-the-clock customer service,
            offering instant, personalized interactions that boost satisfaction
            and loyalty.
        </Card>
        <Card>
            <template #title> Content Generation Automated </template>
            Use AI to effortlessly create engaging, high-quality content across
            various digital platforms, ensuring a consistent and captivating
            online presence.
        </Card>
        <Card>
            <template #title> Data Insights Unlocked </template>
            Employ AI to analyze internal data, predict trends, and inform
            strategic decisions, transforming raw data into actionable insights.
        </Card>
        <Card>
            <template #title> Video Content Accessibility </template>
            Enhance your video content with advanced AI transcription services,
            making it searchable and more accessible, thus improving SEO and
            user experience.
        </Card>
        <Card>
            <template #title> Personalized Interactions </template>
            Integrate AI technologies like RAG for chatbots that deliver
            contextually relevant responses, elevating customer engagement to
            new heights.
        </Card>
        <Card>
            <template #title> GDPR-Compliant Deployments </template>
            Ensure data privacy and compliance by utilizing GDPR-compliant AI
            deployments, safeguarding your business and customer data.
        </Card>
        <Card>
            <template #title> Custom AI Strategy </template>
            Tailor AI solutions to your unique business needs, driving
            efficiency, innovation, and growth by advising on the best AI
            technologies to implement.
        </Card>
    </section>
</template>
